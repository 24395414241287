<template>
  <page-content>
    <h1 class="page-header">
      {{ $t('inventory-items.page.productsInventoryOverview') }}
    </h1>
    <panel
      id="inventory-overview"
      :title="$t('overview')"
    >
      <div class="row ">
        <div v-if="isLoading" class="vgt-loading vgt-center-align position-static">
          <slot name="loadingContent">
            <span class="vgt-loading__content">
              Loading...
            </span>
          </slot>
        </div>
        <div class="col-sm-7">
          <div class="card border-0 text-truncate mb-3 bg-gray-200">
            <div class="card-body">
              <div class="mb-3 text-black">
                <b class="mb-3">
                  {{ $t('inventory-items.page.topInventoryItems') }}
                </b>
                <span class="ms-2">
                  <i v-b-popover.hover="$t('popover.topInventoryItems')" class="fa fa-info-circle"/>
                </span>
              </div>
              <div v-for="item in inventory.items"
                   :key="item.sku"
                   class="d-flex mb-2"
              >
                <div class="d-flex align-items-center">
                  <i class="fa fa-circle text-green fs-8px me-2"/>
                  {{ item.sku }} - {{ item.sku_description }}
                </div>
                <div class="d-flex align-items-center ms-auto">
                  <div class="w-50px text-end ps-2 fw-bold">
                    {{ item.total }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel>
    <panel
      id="inventory-panel"
      :backButtonVisible="false"
      :exportButtonVisible="true"
      :reloadButtonVisible="true"
      :resizeable="true"
      :title="$t('inventoryItems')"
      @exportContent="exportXls"
      @reload="load(null)"
    >
      <inventory
        ref="inventory"
        :data="inventory"
        :isLoading="isLoading"
        @filter="filter"
        @reload="load"
        @sort="sort"
        @filter-button="getInventoryItems"
      />
    </panel>
  </page-content>
</template>
<script>
import { client } from '@/client/client'
import PageContent from '../../../layout/PageContent'
import Inventory from '../components/InventoryItems'
import { debounce } from 'lodash'
import { bus } from '@/main'

export default {
  components: {PageContent, Inventory},
  data() {
    const date_from = new Date()
    date_from.setDate(date_from.getDate() - 7)
    return {
      activeRequests: 0,
      inventory: {},
      isLoading: false,
      request: {
        per_page: 20,
        page: 1,
        order_column: 'sku',
        order_dir: 'DESC',
        filters: {cpy: this.$store.getters.currentCpy},
        predefined: ''
      }
    }
  },
  mounted() {
    this.$store.watch((state, getters) => getters.userId, this.load)
    this.request.filters.cpy = this.$store.getters.currentCpy
    bus.$on('cpyChanged', (data) => {
      this.request.filters.cpy = data
      this.load()
    })
  },
  beforeDestroy() {
    bus.$off('cpyChanged')
    const modal = this.$refs.modalDetail
    if (modal) {
      modal.$off('ok')
    }
  },
  watch: {
    activeRequests(newValue) {
      this.isLoading = newValue > 0
    }
  },
  methods: {
    async exportXls() {
      this.activeRequests++
      try {
        const response = await client.inventory_items().download_excel({...this.request, per_page: 5000})

        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        const contentDisposition = response.headers['content-disposition']
        link.download = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '')

        link.click()
        URL.revokeObjectURL(url)

      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.activeRequests--
      }
    },
    getInventoryItems() {
      this.activeRequests++
      this.request.cpy = this.$store.getters.currentCpy
      this.request.filters.cpy = this.$store.getters.currentCpy
      client.inventory_items().all(this.request)
        .then(response => {
          this.isLoading = false
          this.inventory = response.data
        }).finally(() => {
        this.activeRequests--
      })
    },
    load(event) {
      if (event) {
        this.request.per_page = event.currentPerPage
        this.request.page = event.currentPage
      }
      this.getInventoryItems()
    },
    sort(event) {
      if (event[0].type === 'none') {
        this.request.order_column = event[0].field
        this.request.order_dir = 'desc'
      } else {
        this.request.order_column = event[0].field
        this.request.order_dir = event[0].type
      }
      this.getInventoryItems()
    },
    filter: debounce(function (event) {
      if (event && event.columnFilters) {
        this.request.filters = {...event.columnFilters}
      }
      this.getInventoryItems()
    }, 500),
    resetFilters() {
      this.request.predefined = ''
      this.request.filters = {}
      this.request.filters.cpy = this.$store.getters.currentCpy
      this.request.order_column = 'sku'
      this.request.order_dir = 'DESC'
      this.$refs.inventory.reset()
      this.getInventoryItems()
    }
  }
}
</script>

