<template>
  <div>
    <vue-good-table
      ref="inventory"
      :columns="columns"
      :isLoading="isLoading"
      :lineNumbers="false"
      :mode="'remote'"
      :paginationOptions="tablePaginationOptions"
      :responsive="true"
      :rows="data.data"
      :search-options="{ enabled: false, placeholder: 'Search' }"
      :selectOptions="tableSelectOptions"
      :sort-options="tableSortOptions"
      :total-rows="data.total"
      class="inventory-table"
      @on-page-change="load"
      @on-per-page-change="load"
      @on-column-filter="filterChanged"
      @on-sort-change="onSortChange"
    />
  </div>
</template>

<script>

export default {
  name: 'Inventory',
  props: ['data', 'isLoading'],
  data() {
    return {
      columns: [
        {
          label: 'Stock facility',
          field: 'stofcy',
          width: '5%',
          thClass: 'text-center align-middle',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'stofcy'
          }
        },
        {
          label: 'Stock facility name',
          field: 'stofcyname',
          width: '5%',
          thClass: 'text-center align-middle',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'stofcyname'
          }
        },
        {
          label: 'SKU',
          field: 'sku',
          width: '5%',
          thClass: 'text-center text-nowrap align-middle',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'SKU'
          }
        }, {
          label: 'TITLE',
          field: 'sku_description',
          width: '10%',
          thClass: 'text-center text-nowrap align-middle',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'TITLE'
          }
        }, {
          label: 'EAN',
          field: 'ean',
          width: '5%',
          thClass: 'text-center text-nowrap align-middle',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'EAN'
          }
        }, {
          label: 'TARRIF',
          field: 'customs_tarrif_nr',
          width: '5%',
          thClass: 'text-center text-nowrap align-middle',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'TARRIF'
          }
        }, {
          label: 'WEIGHT',
          field: 'weight',
          width: '5%',
          thClass: 'text-center text-nowrap align-middle',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: true,
            placeholder: 'WEIGHT'
          }
        }, {
          label: 'Total quantity',
          field: 'stk_quantity',
          width: '4%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'Total quantity'
          }
        }, {
          label: 'Aloc. quantity',
          field: 'allocated_qty',
          width: '4%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'Aloc. quantity'
          }
        }, {
          label: 'Available quantity',
          field: 'stk_available_quantity',
          width: '4%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'Available quantity'
          }
        }, {
          label: 'EXPECTED',
          field: 'expected_stock',
          width: '4%',
          thClass: 'text-center align-middle',
          tdClass: 'text-center text-nowrap',
          filterOptions: {
            enabled: false,
            placeholder: 'EXPECTED'
          }
        }, {
          label: this.$t('inventory-items.pickingStorage'),
          field: 'ks',
          width: '4%',
          tdClass: 'text-center',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'KS'
          }
        }, {
          label: this.$t('inventory-items.replenishment'),
          field: 'ns',
          width: '4%',
          tdClass: 'text-center',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'NS'
          }
        }, {
          label: this.$t('inventory-items.defectStorage'),
          field: 'de',
          width: '4%',
          tdClass: 'text-center',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'DE'
          }
        }, {
          label: this.$t('inventory-items.expiredStorage'),
          field: 'vl',
          width: '4%',
          tdClass: 'text-center',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'VL'
          }
        }, {
          label: this.$t('inventory-items.packagingMaterial'),
          field: 'vp',
          width: '4%',
          tdClass: 'text-center',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'VP',
          }
        }, {
          label: this.$t('inventory-items.manualPicking'),
          field: 'mk',
          width: '4%',
          tdClass: 'text-center',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'MK'
          }
        }, {
          label: this.$t('inventory-items.rejected'),
          field: 'au',
          width: '4%',
          tdClass: 'text-center',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'AU'
          }
        }, {
          label: this.$t('inventory-items.incomingGoodsLocation'),
          field: 'we',
          width: '4%',
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'WE'
          }
        }, {
          label: this.$t('inventory-items.returnStorage'),
          field: 'rt',
          width: '4%',
          tdClass: 'text-center',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'RT'
          }
        }, {
          label: this.$t('inventory-items.productionStorage'),
          field: 'pl',
          width: '4%',
          tdClass: 'text-center',
          thClass: 'text-center align-middle',
          filterOptions: {
            enabled: true,
            placeholder: 'PL'
          }
        }
      ],
      tablePaginationOptions: {
        enabled: true,
        position: 'bottom',
        perPage: 20,
        perPageDropdown: null,
        perPageDropdownEnabled: true,
        dropdownAllowAll: false,
        mode: 'records', // or pages
        infoFn: null,
        jumpFirstOrLast: true
      },
      tableSelectOptions: {
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
      },
      tableSortOptions: {
        enabled: true,
        initialSortBy: {field: 'sku', type: 'desc'}
      },
    }
  },
  methods: {
    load(event) {
      this.$emit('reload', event)
    },
    onSortChange(event) {
      if (event) {
        this.$emit('sort', event)
      }
    },
    filterChanged(event) {
      this.$emit('filter', event)
    },
    reset() {
      this.$refs.inventory.reset()
    }
  },
}
</script>

