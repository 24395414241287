<template>
  <div>
    <b-alert
      :show="warning != null"
      variant="warning mt-2"
    >
      {{ warning }}
    </b-alert>
    <b-alert
      :show="error != null"
      variant="danger mt-2"
    >{{ error }}
    </b-alert>
    <b-alert
      v-if="success"
      :show="success"
      variant="success mt-2"
    >
      {{ $t('addressEditedSuccessfully') }}
    </b-alert>
    <b-card :title="$t('address')" class="mt-2">
      <div class="w-100 d-flex justify-content-end">
        <b-button @click="openMaps">
          {{ $t('openMaps') }}
          <i class="fa-solid fa-map-location-dot" style="margin-left: 5px;"/>
        </b-button>
      </div>
      <b-form
        v-if="addressCopy"
        novalidate
        @submit.prevent="onSubmit"
      >
        <div class="row">
          <b-form-group
            id="address-ordernr"
            :label="$t('orderNumberCapital')"
            class="col"
            label-for="address-input-ordernr"
          >
            <b-form-input
              id="address-input-ordernr"
              v-model="addressCopy.orderNr"
              disabled
            />
          </b-form-group>
          <b-form-group
            id="address-deliverynr"
            :label="$t('deliveryNumber')"
            class="col"
            label-for="address-input-deliverynr"
          >
            <b-form-input
              id="address-input-deliverynr"
              v-model="addressCopy.deliveryNr"
              :readonly="readonlyInput"
              disabled
            />
          </b-form-group>
        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-name1"
            :label="$t('name1')"
            class="col"
            label-for="address-input-name1"
          >
            <b-form-input
              id="address-input-name1"
              v-model="addressCopy.name1"
              :readonly="readonlyInput"
              :state="getFieldState('name1')"
              required
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['name1']"
              id="address-input-name1-feedback"
            >{{ groupedValidations['name1'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-name2"
            :label="$t('name2')"
            class="col"
            label-for="address-input-name2"
          >
            <b-form-input
              id="address-input-name2"
              v-model="addressCopy.name2"
              :readonly="readonlyInput"
            />
          </b-form-group>
          <b-form-group
            id="address-company"
            :label="$t('company')"
            class="col"
            label-for="address-input-company"
          >
            <b-form-input
              id="address-input-company"
              v-model="addressCopy.company"
            />
          </b-form-group>
        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-street"
            :label="$t('street')"
            class="col"
            label-for="address-input-street"
          >
            <b-form-input
              id="address-input-street"
              v-model="addressCopy.street"
              :readonly="readonlyInput"
              :state="getFieldState('name1')"
              required
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['street']"
              id="address-input-name1-feedback"
            >{{ groupedValidations['street'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-street2"
            :label="$t('street2')"
            class="col"
            label-for="address-input-street2"
          >
            <b-form-input
              id="address-input-street2"
              v-model="addressCopy.street2"
              :readonly="readonlyInput"
            />
          </b-form-group>
          <b-form-group
            id="address-streetnr"
            :label="$t('streetNumber')"
            class="col-2"
            label-for="address-input-streetnr"
          >
            <b-form-input
              id="address-input-streetnr"
              v-model="addressCopy.streetNr"
              :readonly="readonlyInput"
            />
          </b-form-group>
        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-country"
            :label="$t('country')"
            class="col"
            label-for="address-input-country"
          >
            <b-form-input
              id="address-input-country"
              v-model="addressCopy.country"
              :readonly="readonlyInput"
              :state="getFieldState('country')"
              required
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['country']"
              id="address-input-country-feedback"
            >{{ groupedValidations['country'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-city"
            :label="$t('city')"
            class="col"
            label-for="address-input-city"
          >
            <b-form-input
              id="address-input-city"
              v-model="addressCopy.city"
              :readonly="readonlyInput"
              :state="getFieldState('city')"
              required
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['city']"
              id="address-input-city-feedback"
            >{{ groupedValidations['city'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-zip"
            :label="$t('zipCode')"
            class="col-2"
            label-for="address-input-zip"
          >
            <b-form-input
              id="address-input-zip"
              v-model="addressCopy.zip"
              :readonly="readonlyInput"
              :state="getFieldState('zip')"
              required
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['zip']"
              id="address-input-zip-feedback"
            >{{ groupedValidations['zip'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-email"
            :label="$t('email')"
            class="col"
            label-for="address-input-email"
          >
            <b-form-input
              id="address-input-email"
              v-model="addressCopy.email"
              :readonly="readonlyInput"
              :state="getFieldState('email')"
              type="email"
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['email']"
              id="address-input-email-feedback"
            >{{ groupedValidations['email'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-phone"
            :label="$t('phone')"
            :state="getFieldState('phone')"
            class="col"
            label-for="address-input-phone"
          >
            <b-form-input
              id="address-input-phone"
              v-model="addressCopy.phone"
              :readonly="readonlyInput"
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['phone']"
              id="address-input-phone-feedback"
            >{{ groupedValidations['phone'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div
          v-if="addressCopy.trackingNr || addressCopy.trackingUrl"
          class="row mt-2"
        >
          <b-form-group
            id="address-trackingNr"
            :label="$t('trackingNumber')"
            class="col"
            label-for="address-input-trackingNr"
          >
            <b-form-input
              id="address-input-trackingNr"
              v-model="addressCopy.trackingNr"
              readonly
            />
          </b-form-group>
          <b-form-group
            id="address-trackingUrl"
            :label="$t('trackingUrl')"
            class="col"
            label-for="address-input-trackingUrl"
          >
            <b-form-input
              id="address-input-trackingUrl"
              v-model="addressCopy.trackingUrl"
              readonly
            />
          </b-form-group>
        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-shipper"
            :label="$t('orders.delivery-detail.shipper')"
            class="col"
            label-for="address-input-shipper"
          >
            <b-form-select
              id="address-input-shipper"
              v-model="shipper"
              :disabled="!!addressCopy.trackingNr || !!addressCopy.trackingUrl"
              class="form-select"
            >
              <option :value="null">{{ $t('choose') }}</option>
              <option
                v-for="(item, index) in shipperItemsData"
                :key="index" :value="item"
              >
                {{ item.description ? `${item.shipper}, ${item.description}` : item.shipper }}
              </option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            id="address-shipper-service-code"
            :label="$t('shipperServiceCode')"
            class="col"
            label-for="address-input-shipper-service-code"
          >
            <b-form-input
              id="address-input-shipper-service-code"
              v-model="shipperServiceCode"
              :disabled="!!addressCopy.trackingNr || !!addressCopy.trackingUrl"
              :readonly="!!shipper.code"
            />
          </b-form-group>
        </div>
        <div
          v-if="shipper.shipper.includes('DHLDE')"
          class="row mt-2"
        >
          <b-form-group
            id="packstation"
            class="col"
            label="Packstation"
            label-for="packstation-input"
          >
            <b-form-input
              id="packstation-input"
              v-model="packstation"
            />
          </b-form-group>
          <b-form-group
            id="postnumber"
            class="col"
            label="Postnumber"
            label-for="postnumber-input"
          >
            <b-form-input
              id="postnumber-input"
              v-model="postnumber"
            />
          </b-form-group>
        </div>
        <b-button
          v-if="!addressCopy.trackingNr"
          :disabled="loading"
          class="mt-3"
          type="submit"
          variant="primary"
        >
          <b-spinner v-if="loading" small/>
          <span> {{ loading ? 'Loading...' : 'Update address' }}</span>
        </b-button>
      </b-form>
    </b-card>
    <notifications group="msg" position="bottom right"/>
  </div>
</template>

<script>
import {client} from '@/client/client'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
  name: 'ChangeAddressForm',
  props: ['address'],
  emits: ['updateAddress'],
  data() {
    return {
      addressCopy: null,
      error: null,
      success: false,
      warning: null,
      loading: false,
      stateInvalid: false,
      validations: null,
      shipperItemsData: [],
      shipperServiceCode: null,
      shipper: {
        shipper: '',
        description: ''
      },
      packstation: null,
      postnumber: null,
      GOOGLE_MAPS_API_URL: 'https://www.google.com/maps/search'
    }
  },
  async mounted() {
    await this.getShipperItems()

    this.addressCopy = {...this.$props.address}
    this.stateInvalid = false

    this.checkShipperAndServiceCode()

    if (this.addressCopy.trackingNr) this.showError('Label already exists, address change is not possible')
    this.warning = this.addressCopy.shipErrorMsg || null
  },
  computed: {
    groupedValidations() {
      return this.validations.reduce((grouped, validation) => {
        const name = validation.path.split('.')[1]
        const message = validation.message.split(': ')[1]
        grouped[name] = {...validation, message}
        return grouped
      }, {})
    },
    readonlyInput() {
      return !!this.addressCopy.trackingNr
    }
  },
  watch: {
    shipper(newValue) {
      this.shipperServiceCode = newValue.code || ''
    }
  },
  methods: {
    checkShipperAndServiceCode() {
      if (this.isValidValue(this.addressCopy.shippingServicecode)) {
        this.shipperServiceCode = this.addressCopy.shippingServicecode
      }

      if (this.isValidValue(this.addressCopy.shipper)) {
        this.shipper = this.findShipper(this.addressCopy.shipper, this.shipperServiceCode)
      }
    },
    isValidValue(value) {
      return value && value !== ' '
    },
    findShipper(shipper, serviceCode) {
      if (serviceCode) {
        return this.shipperItemsData.find(item =>
          item.shipper === shipper && (!item.code || item.code === serviceCode)
        )
      } else {
        const shippers = this.shipperItemsData.filter(item => item.shipper === shipper)
        return shippers[0]
      }
    },
    openMaps() {
      const {city, street} = this.addressCopy
      if (city && street) {
        const addressString = `${street} ${city}`
        const encodedAddress = encodeURIComponent(addressString)
        const googleMapsUrl = `${this.GOOGLE_MAPS_API_URL}/?api=1&query=${encodedAddress}`
        window.open(googleMapsUrl, '_blank')
      } else {
        this.$notify({
          group: 'msg',
          text: 'To view location on Google Maps, the city and street fields must be filled',
          duration: 5000
        })
      }
    },
    showError(message) {
      this.error = message
      setTimeout(() => {
        this.error = null
      }, 5000)
    },
    async onSubmit() {
      try {
        this.resetFormState()
        this.normalizeEmptyValues()
        this.loading = true

        if (this.postnumber) {
          this.addressCopy = {...this.addressCopy, 'street4': this.postnumber}
        }

        if (this.packstation) {
          this.addressCopy = {...this.addressCopy, 'street5': this.packstation}
        }

        const addressData = {
          ...this.addressCopy,
          shipper: this.shipper.shipper,
          shippingServicecode: this.shipperServiceCode || ''
        }
        await client.change_address().address_change(addressData)

        this.success = true
        this.$emit('updateAddress')
      } catch (error) {
        console.error('Error:', error)
        this.loading = false
        const errorMessage = `${error.response.data.error.code}: ${error.response.data.message}`
        this.showError(errorMessage)

        if (error.response.data.error.code === 'DADCI004') {
          this.stateInvalid = true
          this.validations = error.response.data.error.validation
        }
      } finally {
        this.loading = false
      }
    },
    resetFormState() {
      this.error = null
      this.stateInvalid = false
      this.validations = null
      this.success = false
    },
    normalizeEmptyValues() {
      Object.keys(this.addressCopy).forEach(key => {
        if (this.addressCopy[key] === '') {
          this.addressCopy[key] = null
        }
      })
      this.addressCopy.deliveryNr = this.addressCopy.deliveryNr || ''
    },
    getFieldState(name) {
      return this.stateInvalid && this.groupedValidations[name] ? false : null
    },
    transformShippers(shippers) {
      return shippers.flatMap(shipper => {
        if (shipper.services) {
          return shipper.services.map(({description, code}) => ({
            shipper: shipper.shipper,
            description,
            code
          }))
        } else {
          return {...shipper}
        }
      })
    },
    async getShipperItems() {
      this.loading = true
      try {
        const response = await client.create_order().shippers_get({cpy: this.$store.getters.currentCpy, count: 45})
        this.shipperItemsData = this.transformShippers(response.data.$resources)
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-group.required .control-label:after {
  content: "*";
  color: red;
}
</style>
